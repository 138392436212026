<!--  -->
<template>
    <div class='historypage'>
        <div align="center">
            <div id="DomPdf" style="width:794px;height:1123px;border:1px solid #000000;">
                <div style="margin:45px 57px">
                    <el-row class="title">
                        <el-col :span="6">
                            <div class="blank"></div>
                        </el-col>
                        <el-col :span="12"><span style="text-align: center; display:inline-block; font-size: 24px;">
                                胎心仪检测报告
                            </span></el-col>
                        <el-col :span="6"><img crossorigin="anonymous" style="width:110px"
                                src="../../../../../public/img/logo_xingou.png" /></el-col>
                    </el-row>
                    <!-- 绘制双横线 -->
                    <hr style="height:2px;border:none;border-top:7px double gray;margin-top: 2px;" />
                    <el-row class="info">
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                姓名：{{ realName }}</span></el-col>
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                性别：{{ gender === 1 ? '男' : '女' }}</span></el-col>
                        <el-col :span="5"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                年龄：{{age}}</span></el-col>
                        <el-col :span="9"><span style="text-align: center; display:inline-block; font-size: 16px;">
                                时间：{{time}} </span></el-col>
                    </el-row>

                    <div id="myChart" style="width: 100%;height:300px;"></div>
                    <div id="myPie" style="width: 100%;height:400px;"></div>
                </div>
                <!-- <canvas id="ecg" width="793.7px" height="1122.52px" style="border:black 1px solid">您的浏览器不支持canvas</canvas><br> -->
                <el-button type="primary" plain @click="getPdf('#DomPdf')">下载报告</el-button>
            </div>
        </div>
    </div>
</template>
    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { runInThisContext } from 'vm';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            htmlTitle: 'PDF_FHR',
            time: '',
            heartRate: [],
            overRate: 0,
            lowRate: 0,
            normRate: 0,
            header: {},
            realName:'',
            gender:'',
            age:'',
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        initECGChart(domName) {
            this.myChart = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        initPieChart(domName) {
            this.myPie = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
        },
        Babychart() {
            this.option = {
                xAxis: {
                    type: 'value',
                    name: '时间(s)'
                },
                yAxis: {
                    type: 'value',
                    name: '心率(次/min)'
                },
                dataset: [
                    {
                        source: [
                            this.x,
                            this.heartRate
                        ]
                    },
                ],
                series: [
                    {
                        datasetIndex: 0,
                        symbol: "none" /*去掉小圆点*/,
                        // name: '心率',
                        type: "line",
                        seriesLayoutBy: "row",
                    },
                ],
                legend: {

                }
            };
            this.myChart.setOption(this.option);
        },
        BabyPie() {
            this.Pieoption = {
                title: {
                    text: '诊断结论汇总',
                    subtext: '仅供参考',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 40,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true }
                    }
                },
                series: [
                    {
                        name: '胎心诊断结论',
                        type: 'pie',
                        radius: ['20%', '60%'],
                        center: ['50%', '60%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            normal: {
                                color: function (colors) {
                                    var colorList = [
                                        '#F4D03F',
                                        '#E74C3C',
                                        '#91cd77',
                                    ];
                                    return colorList[colors.dataIndex];
                                },
                                borderRadius: 5,
                                borderColor: '#fff',
                                borderWidth: 2
                            },

                        },
                        data: [
                            { value: this.overRate, name: '心率过速' },
                            { value: this.lowRate, name: '心率过缓' },
                            { value: this.normRate, name: '心率正常' },
                        ]
                    }
                ]
            };
            this.myPie.setOption(this.Pieoption);
        },
        getinfo() {
            this.$http.adornHeader(this.header)
            if (this.$store.state.module.crossPlatform) {
                this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
            }
            this.$http({
                url: this.$http.adornUrl('/personal/info/getBasicInfo'),
                method: 'get',
                // headers: this.$http.adornHeader(this.header), 
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                    this.gender = data.gender;
                    this.age = data.age;
                    this.realName = data.realName
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.time = this.$route.query.fhrdate
        this.uid = this.$route.query.uid
        var conclusion = this.$route.query.hbvalue.split(',')
        this.heartRate = conclusion.filter((item) => {  // 过滤所有零值
            return String(item) != '0';
        })
        console.log(this.heartRate)
        this.x = [];
        for (let i = 0; i < this.heartRate.length; i++) {
            this.x.push(i);
            if (this.heartRate[i] > 160) {
                this.overRate++
            } else if (this.heartRate[i] < 100) {
                this.lowRate++;
            } else {
                this.normRate++
            }
        }

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.initECGChart(document.getElementById("myChart"));
        this.initPieChart(document.getElementById("myPie"));
        this.Babychart()
        this.BabyPie()
        this.getinfo()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.blank {
    border-radius: 4px;
    height: 18px;
}
</style>